import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IPensionConfigurationEntity } from '../../interfaces/api/pension-configuration-entity';

export const adapter: EntityAdapter<IPensionConfigurationEntity> =
  createEntityAdapter<IPensionConfigurationEntity>({});

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedPensionConfigurationId: null,
});
