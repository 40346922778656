import { Action } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import { IPensionConfigurationEntity } from '../../interfaces/api/pension-configuration-entity';

export enum PensionConfigurationSignalTypes {
  LOAD_PENSION_CONFIGURATION = '[PensionConfiguration][Signal] Load Collection',
}
export enum PensionConfigurationMessageTypes {
  SET_COLLECTION = '[PensionConfiguration][Message] Set Collection',
  ADD_ONE = '[PensionConfiguration][Message] Add One',
  UPDATE_ONE = '[PensionConfiguration][Message] Update One',
  UPSERT_ONE = '[PensionConfiguration][Message] Upsert One',
  DELETE_ONE = '[PensionConfiguration][Message] Delete One',
  ADD_MULTIPLE = '[PensionConfiguration][Message] Add All',
  DELETE_MULTIPLE = '[PensionConfiguration][Message] Delete Many',
  UPSERT_MULTIPLE = '[PensionConfiguration][Message] Upsert Many',
  UPDATE_MULTIPLE = '[PensionConfiguration][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = PensionConfigurationMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IPensionConfigurationEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = PensionConfigurationMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IPensionConfigurationEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = PensionConfigurationMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IPensionConfigurationEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = PensionConfigurationMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IPensionConfigurationEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = PensionConfigurationMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IPensionConfigurationEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = PensionConfigurationMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IPensionConfigurationEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = PensionConfigurationMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IPensionConfigurationEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = PensionConfigurationMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = PensionConfigurationMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type PensionConfigurationMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
